export const environment = {
  production: true,
  tenant: 'tenant1',
  keycloak: {
    enabled: false,
    realm: 'dashboard-demo-realm',
    clientId: 'dashboard-demo-client',
    allRoles: ['USER', 'ADMIN'],
    token: '',
    baseUrl: 'http://10.89.18.199:9999/',
  },
};
